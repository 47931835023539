/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;400;600&display=swap');
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color to match images */
  /*Green dark 190 - Green 171 - Grren Blue 200*/

  /* HSL color mode */
  --first-color: #1976D2;
  --first-color-alt: #3FC6D5;
  --first-color-second: #FCB900;
  --first-color-second-alt: #eec044;
  --title-color: #2773a5;
  --text-color: #111;
  --text-color-light: hsl(202, 51%, 70%);
  --input-color: hsl(190, 24%, 97%);
  --body-color: #f4f4f4;
  --white-color: #FFF;
  --gray-850: #1f2729;
  --scroll-bar-color: hsl(190, 12%, 90%);
  --scroll-thumb-color: hsl(190, 12%, 75%);

  /*========== Font and typography ==========*/
  --body-font: 'Heebo', sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 3rem;
  --h2-font-size: 2.25rem;
  --h3-font-size: 1.8rem;
  --h4-font-size: 1.2rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-light: 100;
  --font-medium: 400;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  --mb-0-25: .25rem;
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*========== Hover overlay ==========*/
  --transition: .3s;
  --img-hidden: hidden;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 4.2rem;
    --h1-font-size: 3.4rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.2rem;
    --h4-font-size: 1rem;
    --normal-font-size: .875rem;
    --small-font-size: .75rem;
    --smaller-font-size: .613rem;
  }
}

@media screen and (max-width: 600px) {
    :root {
        --biggest-font-size: 2rem;
        --h1-font-size: 1.6rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --h4-font-size: .95rem;
        --normal-font-size: .875rem;
        --small-font-size: .75rem;
        --smaller-font-size: .613rem;
      }
}

body{
    font-family: var(--body-font) !important;
    background-color: var(--body-color) !important;
}

nav{
    background-color: var(--white-color) !important;
    font-family: var(--body-font) !important;
}

.navbar-brand{
    margin-right: 4rem !important;
}

.nav-link{
    font-weight: var(--font-medium) !important;
    font-size: var(--h4-font-size) !important;
    padding: 0 .5rem !important;
    letter-spacing: .15rem;
    color: var(--gray-850) !important;
}

.nav-link:hover{
    color: var(--first-color) !important;
}

.navbar-collapse{
    flex-grow: 0 !important;
}

h1, h2, h3, h4, h5, p, input, label{
    font-family: var(--body-font) !important;
}

.btn{
    border-radius: 0px !important;
}

.section{
    padding: 2.5rem 0 2rem;
    height: auto;
}

.section__pay{
    padding: 1rem 0 .5rem;
    height: auto;
}

#home {
    background-image: url('/images/banner_header.jpg');
    background-repeat: no-repeat;
    height: 650px;
}

.home__content{
    position: relative;
    top: 40%;
}

#home h1{
    color: var(--white-color);
    font-size: var(--h1-font-size);
    font-weight: var(--font-semi-bold);
    line-height: 1.5;
    letter-spacing: .2rem;
}

.card__home{
    background-color: var(--white-color) !important;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    border-radius: 1rem;
    padding: var(--h3-font-size);
    position: relative;
    top: 20%;
}

.card__home h2{
    color: var(--gray-850);
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    line-height: 1.5;
}

.input__group{
    position: relative;
    margin-bottom: 4px;
}

.input__group select,
.input__group input{
    border: 1px solid var(--gray-850);
    border-radius: 1rem;
    margin-bottom: 6px;
    background-color: none;
    padding: 1rem;
    font-size: 1rem;
    width: 100%;
    color: var(--gray-850);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input__group label{
    position: absolute;
    left: 16px;
    color: var(--first-color);
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input__group select:focus,
.input__group input:focus{
    outline: none;
    border: 1.5px solid var(--first-color);
}

.input__group select:focus ~label,
.input__group input:focus ~label{
    transform: translateY(-10px) scale(0.8);
    background-color:var(--white-color);
    padding: 0 0.2em;
    color: var(--first-color);
}

.input__group select:placeholder-shown,
.input__group input:placeholder-shown {
    border: 1px solid var(--gray-850);
}

.input__group select:not(:placeholder-shown) ~label,
.input__group input:not(:placeholder-shown) ~label{
    transform: translateY(-10px) scale(0.8);
    background-color: var(--white-color);
    padding: 0 0.2em;
    color: var(--first-color);
}

.bg-white{
    background-color: var(--white-color);
}

.h2__first{
    color: var(--first-color);
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
}

.h3__first{
    color: var(--first-color);
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.h4__first{
    color: var(--first-color);
    font-size: var(--h4-font-size);
    font-weight: var(--font-medium);
}

.p__first{
    color: var(--first-color);
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.card__horizontal{
    box-shadow: 0px 2px 6px hsla(230, 100%, 15%, 0.15);
    border-radius: 1rem;
    background-color: var(--white-color);
    color: var(--gray-850);
    padding: 1rem 1rem;
    margin: 6px auto;
}

.card__payment{
    box-shadow: 0px 2px 6px hsla(230, 100%, 15%, 0.15);
    border-radius: 1rem;
    background-color: var(--white-color);
    color: var(--gray-850);
    padding: 1rem 1rem;
    margin: 6px auto;
}

.content__itens{
    margin-top: 10px !important;
}

.card__seguro{
    padding: 1rem 1rem;
}

.card__seguro p{
    font-size: var(--h4-font-size);
    font-weight: var(--font-medium);
    color: var(--body-color);
    margin: 0;
    padding: .5rem 0;
}

.card__plans{
    box-shadow: 0px 2px 6px hsla(230, 100%, 15%, 0.15);
    border-radius: .5rem;
    background-color: #F8F8F8;
    padding: 1rem 1rem;
    margin: 4px auto;
    transition: 0.3s ease;
}

.card__plans:hover{
    box-shadow: 4px 4px 27px 0 rgb(0 0 0 / 27%);
}

.card__plans p{
    color: var(--gray-850);
    font-size: var(--normal-font-size);
    font-weight: 400;
}

.card__horizontal i{
    color: var(--first-color);
    font-size: var(--h1-font-size);
    font-weight: var(--font-light);
}

#beneficios{
    background-color: var(--white-color);
    padding: 2.5rem 0;
}

.beneficios__img{
    animation: float 8s ease-in-out infinite;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-50px);
    }
}

article{
    position: relative;
    top: 12%;
}

article h2{
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: .2rem;
}

article h3{
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.5;
}

.swiper-wrapper{
    padding: 2rem 1rem;
}

.swiper-slide{
    box-shadow: 0px 2px 6px hsla(230, 100%, 15%, 0.15);
    background-color: #fff;
    border-radius: 20px;
}

.swiper-slide h3{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--gray-850);
    padding: 0 1rem;
}

.swiper-slide p{
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
    color: var(--gray-850);
    height: 80px;
    padding: 0 1rem;
}

.title__plans{
    color: var(--title-color);
    font-size: var(--h3-font-size);
    font-weight: var(--font-semi-bold);
}

.title__plans i{
    color: var(--gray-850) !important;
    font-size: var(--h4-font-size) !important;
    font-weight: var(--font-light) !important;
}

.price_plans{
    color: var(--first-color-alt);
    font-size: var(--h2-font-size) !important;
    font-weight: 700;
    line-height: 1;
}

.price_plans_desconto{
    color: #8c8c8c;
    font-size: var(--smaller-font-size);
    text-decoration: line-through;
}

.price_plans span{
    color: var(--gray-850) !important;
    font-size: var(--normal-font-size) !important;
    font-weight: var(--font-light) !important;
}

.price_plans_acrescimo{
    color: var(--gray-850);
    font-size: var(--small-font-size) !important;
    font-weight: 700;
    line-height: 1;
}

.btn-group-lg>.btn, .btn-lg {
    --bs-btn-padding-y: 0.9rem !important;
}

.text__compra{
    font-weight: var(--font-semi-bold);
    color: var(--first-color-second);
}

.selected__product {
    border: 2px solid var(--first-color-alt) !important;
}

#productComparar{
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    color: #444;
    background-color: var(--body-color);
    z-index: 999;
    padding: 1rem 0;
}

.form-check-label{
    font-family: var(--body-font);
    font-weight: var(--font-semi-bold);
}

#cardPay{
    margin-top: .5rem;
    padding: .5rem;
}

.form-select,
.form-control{
    border-radius: 0 !important;
    padding: .5rem !important;
}

.parcel-label{
    font-size: var(--small-font-size) !important;
    color: #1f2729 !important;
}

.form-label{
    margin-top: 0.3rem;
}

.end-segurados{
	display: none;
}

footer {
    padding: 2.5rem 0;
    background-color: #354853;
    font-family: var(--body-font);
}

.ul__comp{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ul__comp li a{
    color: #e0dfdf !important;
    text-decoration: none;
}

.ul__comp li a:hover{
    color: #8c8c8c !important;
}

.collapsible__slug {
    cursor: pointer;
    border: none;
    outline: none;
}

.active__slug, .collapsible__slug:hover {
    background-color: #ccc;
}

.content__slug {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.price__comparacao
{
    height: 140px;
}

.mobile{
    display: none;
}

.border__cp{
    border-top: 1px solid #1f2729;
    margin-top: 5px !important;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	margin-top: -15px;
}

@media screen and (max-width: 600px) {
    .mobile{
        display: block !important;
    }

    .desktop{
        display: none !important;
    }

    #home {
        background-color: #FBAF01;
        height: 100vh;
        padding-bottom: 20px;
    }

    article h2{
        font-size: var(--h2-font-size);
    }

    article h3{
        font-size: var(--h3-font-size);
    }
}
